import styled from '@emotion/styled';
import { mix } from 'polished';
import isPropValid from '@emotion/is-prop-valid';

export default styled('ol', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'color',
})`
  ${({ theme }) => theme.typography.control};
  padding: 0;
  margin: 3px 0;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 10px 0;
  color: ${({ theme, color }) => theme.colors[color]};
  & > li {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:first-of-type):before {
      content: '\u2002\u2022\u2002';
    }

    & > a {
      ${({ theme }) => theme.typography.underline};
      font: inherit;
      color: inherit;
      cursor: pointer;
      opacity: inherit;
      margin-bottom: 0;
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: bottom;

      transition: color 150ms linear;
      ${({ theme }) => theme.media.hover} {
        &:hover {
          color: ${({ theme, color }) =>
            mix(0.5)('#FFFFFF')(theme.colors[color])};
        }
      }
      &:active {
        color: ${({ theme, color }) =>
          mix(0.4)('#FFFFFF')(theme.colors[color])};
      }
    }
  }
`;
