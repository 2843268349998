import { useCallback, useEffect, useMemo } from 'react';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { routing } from '../algoliaRouting';
import { unescape } from 'html-escaper';
import { useRefinedItems } from '../../hooks/useRefinedItems';

const attribute = 'make.id';

export const useMakeMenu = ({ hsBrands, hsModels, defaultUpdater }) => {
  const transformItems = useCallback(
    (items) => {
      const byId = Object.fromEntries(
        items.map((i, order) => [i.value, { item: i, order }])
      );

      const initialBrands = Object.values(hsBrands).map((brand) => ({
        label: brand.id,
        value: brand.id,
        count: 0,
        isRefined: false,
      }));

      initialBrands.forEach((initialBrand, index) => {
        const { item: updatedBrand, order } = byId[initialBrand.value] ?? {};

        if (updatedBrand) {
          initialBrands[index] = {
            ...updatedBrand,
            order,
          };
        }
      });

      return initialBrands.sort((a, b) => {
        if (!a.order && a.order !== 0) return 1;
        if (!b.order && b.order !== 0) return -1;

        return a.order - b.order;
      });
    },
    [hsBrands]
  );
  const refinedModelIds = useRefinedItems('model.id').map((i) => i.value);

  const { refine: refineModel } = useRefinementList({
    attribute: 'model.id',
    limit: 1,
  });
  const params = useRefinementList({
    attribute,
    limit: 500,
    transformItems,
  });

  const _items = useRefinedItems(attribute, params.items);

  const items = useMemo(
    () =>
      _items.reduce((array, i) => {
        const hsBrand = hsBrands[i.value];
        if (hsBrand)
          array.push({
            ...i,
            label: unescape(
              `${hsBrand.content}${i?.count ? ` (${i.count})` : ''}`
            ),
            name: unescape(hsBrand.content),
            slug: hsBrand.slug,
          });
        return array;
      }, []),
    [hsBrands, _items]
  );

  const current = useMemo(() => items.filter((i) => i.isRefined), [items]);

  const byValue = useMemo(
    () =>
      Object.fromEntries(
        Object.values(hsBrands).map((brand) => [brand.id, brand])
      ),
    [hsBrands]
  );
  const bySlug = useMemo(
    () =>
      Object.fromEntries(
        Object.values(hsBrands).map((brand) => [brand.slug, brand])
      ),
    [hsBrands]
  );
  useEffect(() => {
    const handle = {
      stateToRoute: (route, state) => {
        const values = route?.refinementList?.[attribute] || [];
        state.brandSlugs = values.map((value) => byValue[value].slug);
      },
      routeToState: (state, route) => {
        const slugs = state.brandSlugs ?? [];
        route.refinementList = route.refinementList || {};
        route.refinementList[attribute] = slugs
          .map((slug) => bySlug[slug]?.id)
          .filter((i) => i);
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, [byValue, bySlug]);

  useEffect(() => {
    refinedModelIds
      .map((id) => ({ id, brandId: hsModels[id]?.brand?.id }))
      .filter(
        ({ brandId }) =>
          brandId && !current.find(({ value }) => value === brandId)
      )
      .map(({ id }) => refineModel(id));
  }, [current]);

  return {
    type: 'multiMenu',
    title: 'Merk',
    state: {
      ...params,
      refine: (v) => {
        params.refine(v);
      },
      clear: () => {
        refinedModelIds.length && refinedModelIds.map((id) => refineModel(id));
        current.map((i) => params.refine(i.value));
      },
      items,
      current,
      isRefined: current.length > 0,
      activeFilterLabel:
        current.length === 1 ? current[0].label : 'Meerdere merken',
    },
  };
};
