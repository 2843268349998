import bovagWarranty from '../../helpers/bovagWarranty';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = ['included', 'optional'].map((i) => ({
  slug: i,
  value: i,
  label: bovagWarranty(i),
  filterLabel: `BOVAG-garantie ${bovagWarranty(i)?.toLowerCase()}`,
}));

export const useBovagWarranty = useListFilter({
  namedItems,
  attribute: 'bovag_warranty',
  urlParam: 'bovag',
  label: 'BOVAG Garantie',
});
