import { useEffect, useMemo } from 'react';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { routing } from '../algoliaRouting';
import { buildTitle } from '../../helpers/adCardContent';
import { unescape } from 'html-escaper';
import { useRefinedItems } from '../../hooks/useRefinedItems';

const attribute = 'model.id';

export const useModelMenu = ({ hsModels, defaultUpdater }) => {
  const refinedMakeIds = useRefinedItems('make.id').map((i) => i.value);
  const { refine: refineMake } = useRefinementList({
    attribute: 'make.id',
    limit: 1,
  });
  const params = useRefinementList({
    attribute,
    limit: 500,
  });

  const _items = useRefinedItems(attribute, params.items);

  const items = useMemo(
    () =>
      _items.reduce((a, i) => {
        const hsModel = hsModels[i.value];
        if (hsModel) {
          const name = unescape(
            buildTitle(
              hsModel.brand && `${hsModel.brand.content}`,
              `${hsModel.name.content}`
            )
          );
          a.push({
            ...i,
            label: `${name} (${i.count})`,
            slug: hsModel.slug,
            makeId: hsModel?.brand?.id,
            name,
            modelName: hsModel.name.content,
          });
        }
        return a;
      }, []),
    [hsModels, _items]
  );
  const current = useMemo(() => items.filter((i) => i.isRefined), [items]);

  const byValue = useMemo(
    () =>
      Object.fromEntries(
        Object.values(hsModels).map((model) => [model.id, model])
      ),
    [hsModels]
  );

  const bySlug = useMemo(
    () =>
      Object.fromEntries(
        Object.values(hsModels).map((model) => [model.slug, model])
      ),
    [hsModels]
  );

  useEffect(() => {
    const handle = {
      stateToRoute: (route, state) => {
        const values = route?.refinementList?.[attribute] || [];
        state.modelSlugs = values.map((value) => byValue[value].slug);
      },
      routeToState: (state, route) => {
        const slugs = state.modelSlugs ?? [];
        route.refinementList = route.refinementList || {};
        route.refinementList[attribute] = slugs
          .map((slug) => bySlug[slug]?.id)
          .filter((i) => i);
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, [byValue, bySlug, items]);

  return {
    type: 'multiMenu',
    title: 'Model',
    state: {
      ...params,
      refine: (v) => {
        const makeId = items.find((i) => i.value === v)?.makeId;
        if (makeId && !refinedMakeIds.includes(makeId)) {
          refineMake(makeId);
        }
        params.refine(v);
      },
      clear: () => {
        current.map((i) => params.refine(i.value));
      },
      items,
      current,
      isRefined: current.length > 0,
      activeFilterLabel:
        current.length === 1 ? current[0].label : 'Meerdere modellen',
    },
  };
};
