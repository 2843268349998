import React from 'react';
import StyledBreadcrumbs from './Breadcrumbs.style';
import StructuredData from './StructuredData';

const Breadcrumbs = ({ breadcrumbs, ...p }) => {
  /** @type {BreadcrumbList} */
  const breadcrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, ix) => {
      /** @type {ListItem} */
      const item = {
        '@type': 'ListItem',
        position: ix + 1,
        name: breadcrumb.link?.name?.content,
        item: breadcrumb.link?.href && {
          '@type': 'WebPage',
          '@id': breadcrumb.link.href,
        },
      };
      return item;
    }),
  };
  return (
    <>
      <StructuredData data={breadcrumbList} />
      <StyledBreadcrumbs {...p}>
        {breadcrumbs.map((breadcrumb, ix) =>
          breadcrumb?.link?.wlDataOnly ? (
            <React.Fragment key={ix} />
          ) : (
            <li key={ix}>
              {breadcrumb.link ? (
                <a href={breadcrumb.link.href}>
                  <span>{breadcrumb.link.name.content}</span>
                </a>
              ) : (
                <span>{breadcrumb.name.content}</span>
              )}
            </li>
          )
        )}
      </StyledBreadcrumbs>
    </>
  );
};
export default Breadcrumbs;
